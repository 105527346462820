import React from "react";
import AnimatedCursor from "react-animated-cursor";


function AnimCursor()
{
    return (
        <AnimatedCursor innerSize={20}  />
    )
}

export default AnimCursor