import React from 'react'

function Footer() {
  return (
   <>
    <div className='container-fluid mt-5 mb-2'>
    <div className='row '>
    <div className='col-12 footer-note-div'>
        <h1 className='footer-note'>Let’s craft your brand journey , today ! </h1>
        <a className="navbar-brand"  href="https://wa.me/+918076022768" target="_blank">
                <img src={`${process.env.PUBLIC_URL}/whatsapp.png`}  />
            </a>
    </div>
    </div>
    </div>
    <div className='container-fluid mt-2 mb-2'>
        <div className='row d-flex justify-content-between align-items-center'>
        <div className='col-md-4 text-center order-md-2'>
            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}`}>
                <img src={`${process.env.PUBLIC_URL}/smalllogo.png`} className="logo" />
            </a>
            </div>
            
            
            <div className='col-md-4 text-center text-md-end order-md-2'>
                <a href="#" className='footer-link'>Privacy Policy</a>
                <a href='#' className='footer-link'>Terms &amp; Conditions</a>
            </div>
            <div className='col-md-4 text-center text-md-start order-md-1'>
                &copy; gryphonemedia. All Rights Reserved
            </div>
        </div>
    </div>
   </>
  )
}

export default Footer