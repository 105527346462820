import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import AnimCursor from '../components/AnimCursor'
import Footer from '../components/Footer';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

function Homepage() {


  const [isLoading, setIsLoading] = useState(true);
  
 
    const [formData, setFormData] = useState({
      name:'',
      email:'',
      mobile:'',
      msg:''
    });
  


  const handleChange = (e) =>
  {
    e.preventDefault();
    setFormData({...formData, [e.target.name]:e.target.value});
    
    
  }

  const handleContact = (e) =>
  {
    e.preventDefault();
    axios.post('https://grphon-backend.vercel.app/sendMail', formData).then(response=>{alert("Thankyou For Contacting Us");}).catch(err =>{console.log(err);});
  }
 
  return (

    <>
    <ToastContainer
position="top-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
    <AnimCursor />
    
      <div className='container-fluid p-0'>
        <div className='row d-flex align-items-center'>
          <div className='col-12'>
          <Navbar />
          </div>
        </div>
      </div>
      <div className='container-fluid p-0 m-0 herobg'>
      <img className="shape-5 img-fluid" src={`${process.env.PUBLIC_URL}/bottom-shape.png`} alt="Shape"></img>
        <div className='row d-flex justify-content-center align-items-center vh-100'>
          <div className='col-12 text-center'>
          <h1 style={{textTransform:"uppercase"}}>Gryphon Media Craft</h1>
            <h5 className='fw-light' style={{textTransform:"uppercase"}}>Your Vision, Our Ideas</h5>
            <a href="#about-section" className='btn btn-primary contact-btn mt-5'>Know More</a>
          </div>
        </div>
      </div>


      <div className='container-fluid about-section mt-5'>
        <div className='container'>
        <div className='row'>
          <div className='col-12 '>
            <div className='position-relative d-flex flex-column'>
             
              <h2>About Us</h2>
              <hr className='orangeDivider' />
            </div>
          </div>

         
        </div>
        <div className='row' id="about-section">
            <div className='col-12'>
              <p>
              <span className='text-orange'>GRYPHON MEDIA CRAFT</span> is a team of young players who laid the foundation
on the basis of creativity and hard work. Established in 2021 and with a
combined experience of 25+ years, we are a proud agency with numerous
happy clientele and works to boast about. Our core strength lies in services
such as events, corporate conferences and exhibitions. Our services include
Brand strategies, creative design, advertising, photography and videography,
website development, social media marketing and PR. GRYPHON always
worked with a vision to partner with clients to celebrate the small and big
felicity of the organization through our services. With expertise in Event
management and Marketing; we have an upper hand in technicality in
providing customized solutions.
              </p>
              <p>
              <span className='text-orange'>GRYPHON MEDIA CRAFT</span> is a one stop solution for your brand requirements to
be fulfilled with the pinch of perfection and magnificence.
              </p>
              
              <p>
              <span className='text-orange'>GRYPHON MEDIA CRAFT</span> believes in creating real-life brand experiences that help you
gather your target audience in a single location and spark their curiosity about
your offering. Providing you a platform to connect with your audience in the
real world, boosting brand awareness and fostering business growth.
              </p>

              <span className='text-orange' style={{fontSize:"1.2rem"}}>We offer solutions differently. Want to know how?</span>
              
              <p>We believe it is imperative to gain a thorough understanding of your brand
and target audience before we create an event and brand strategy for your
business. As an event marketing agency, we uniquely tell your brand’s story.
We leverage technology to create engaging digital experiences and offer
sensory-rich live events that allow attendees to experience a brand fully. We
deliver dynamic, personalized, and connected events, be it a small, exclusive
gathering for C-suite executives or a large-scale conference.</p>

<p>
We strive to create the flawless and ideal blend of zest and liveliness. We
believe in working with emphasizing on the personal touch and believe in the
new beginnings in a manner so that the event and advertising turns out to
enhance emotions. GRYPHON understands the importance of a vision for you
and we bring our ideas to make it all worth it.
</p>

            </div>
           
        </div>
        </div>

        
      </div>


      <div className='container mt-4'>
  <div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
       
        <h2>Our Services</h2>
        <hr className='orangeDivider' />
      </div>
    </div>
  </div>

  <div className='row d-flex align-items-stretch' id="services">
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>01</span>
        <h3>Events &amp; Exhibitions</h3>
        <p>We bring exclusive brands in spectacular settings, ensuring that every event
is a showcase of elegance and exclusivity. Focused to deliver unmatched
quality and flawless execution with meticulous planning in our event services.</p>
      </div>
    </div>
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>02</span>
        <h3>Brand Strategy</h3>
        <p>We specialize in creating immersive experiences that amplify brand presence
and engage discerning audiences. With expertise spanning Branding-
Advertising, Event Management, MICE (Meetings, Incentives, Conferences,
and Exhibitions).</p>
      </div>
    </div>
    <div className='col-md-4 '>
      <div className='service-card h-100'>
        <span className='cardCount'>03</span>
        <h3>Social Media Marketing</h3>
        <p>SMM is a popular type of marketing
that leverages social media platforms to achieve business goals. Our team of
experts are specialized in digital marketing and the latest trends and tools to
enhance your digital presence to promote brands, products, or services.</p>
      </div>
    </div>
  </div>

  <div className='row mt-3 d-flex align-items-stretch'>
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>04</span>
        <h3>Website Development</h3>
        <p>At GRYPHON we understand that your website is the virtual storefront of
your company, and it’s often the first point of interaction between you and your
potential customers.</p>
      </div>
    </div>
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>05</span>
        <h3>PR</h3>
        <p>We facilitate your brand press release in any part of India with our
association with national, regional, and vernacular media. Our
widespread associate network that delivers the best mileage for
our clients by creating visibility that supports the brand purpose in
any specified/targeted region.</p>
      </div>
    </div>
    <div className='col-md-4'>
      <div className='service-card h-100'>
        <span className='cardCount'>06</span>
        <h3>Photography &amp; Videography</h3>
        <p>We specialize in creating dynamic visual content that captures the
essence of brands and events alike. From promotional videos to
photography services, we help brands to create visual narrative that is
not only seen but reminisced by potential customers.</p>
      </div>
    </div>
  </div>
</div>

<div className="container mt-4">

<div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
       
        <h2>Our Work</h2>
        <hr className='orangeDivider' />
      </div>
    </div>
</div>
      <div className='row'>
        <div className='col-12'>
          {/* First row of slider */}
      <Swiper  modules={[Autoplay]}
       
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        breakpoints={{
    640: {
      slidesPerView: 3, // Show 2 slides on devices with width <= 640px
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 4, // Default setting for larger screens
      spaceBetween: 10,
    },
  }}
        speed={3000}>
         <SwiperSlide >
            <img src={`${process.env.PUBLIC_URL}/gallery/g1.jpg`} className="gallerySlideImg"/>
           
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g2.jpg`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g5.png`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g4.jpg`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide >
            <img src={`${process.env.PUBLIC_URL}/gallery/g1.jpg`} className="gallerySlideImg"/>
           
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g6.png`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g7.png`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g8.png`} className="gallerySlideImg"/>
          </SwiperSlide>
      </Swiper>

      {/* Second row of slider */}
      <Swiper  modules={[Autoplay]}
       
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        breakpoints={{
    640: {
      slidesPerView: 3, // Show 2 slides on devices with width <= 640px
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 4, // Default setting for larger screens
      spaceBetween: 10,
    },
  }}
        speed={3000}>
        <SwiperSlide >
            <img src={`${process.env.PUBLIC_URL}/gallery/g9.png`} className="gallerySlideImg"/>
           
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g1.jpg`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g4.jpg`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g6.png`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide >
            <img src={`${process.env.PUBLIC_URL}/gallery/g4.jpg`} className="gallerySlideImg"/>
           
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g7.png`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g3.jpg`} className="gallerySlideImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/gallery/g8.png`} className="gallerySlideImg"/>
          </SwiperSlide>
      </Swiper>
        </div>
      </div>
    </div>




    <div className="container mt-4">

<div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
       
        <h2>Our Clients</h2>
        <hr className='orangeDivider' />
      </div>
    </div>
</div>
      <div className='row'>
        <div className='col-12'>
          {/* First row of slider */}
      <Swiper  modules={[Autoplay]}
       
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        breakpoints={{
    640: {
      slidesPerView: 3, // Show 2 slides on devices with width <= 640px
      spaceBetween: 10,
    },
    1024: {
      width:1024,
      slidesPerView: 6, // Default setting for larger screens
      spaceBetween: 10,
    },
  }}
        speed={2000}>
         <SwiperSlide >
            <img src={`${process.env.PUBLIC_URL}/clients/mod.png`} className="clientImg"/>
           
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/raphe.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/gulshan.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/iss.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/paramount.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/ramboll.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/sierra.png`} className="clientImg"/>
          </SwiperSlide>
          <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/clients/worldtrail.png`} className="clientImg"/>
          </SwiperSlide>
         
      </Swiper>

      
        </div>
      </div>
    </div>


    {/*<div className='container-fluid about-section mt-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
       
        <h2>Testimonials</h2>
        <hr className='orangeDivider' />
      </div>
    </div>
</div>
      <div className='row'>
       
        <div className='row mt-4 d-flex align-items-stretch'>
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>01</span>
      
        <p>We worked on an important PR campaign for UrbanClap.The team’s media strategy significantly influenced the government’s GST decision on household services. This required months of effort and
        substantial press engagement.Kudos to the team for championing this cause. I wish them success.</p>
        <h6 className='text-end mt-2 fw-bold'>- Urban Company</h6>
      </div>
    </div>
    <div className='col-md-4 mb-3'>
      <div className='service-card h-100'>
        <span className='cardCount'>02</span>
       
        <p>The team representing PR forComic Con India has been an absolute delight to work with.
        From managing and building our show coverage, to handling crises – they have always delivered on each front. I look forward to working with them in the coming years and building upon the successes we have achieved together.</p>
        <h6 className='text-end mt-2 fw-bold'>- Comi Con</h6>
      </div>
    </div>
    <div className='col-md-4'>
      <div className='service-card h-100'>
        <span className='cardCount'>03</span>
        
        <p>The PR team has done
outstanding work in building my profile and the profiles of my investee companies.Their
strategic approach and
dedication have delivered
exceptional results. Highly
recommended!</p>
<h6 className='text-end mt-2 fw-bold'>- KDS Global</h6>
      </div>
    </div>
  </div>
        </div>
    </div>
     
    </div>*/}


    <div className='container'>
    <div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
       
        <h2>Contact Us</h2>
        <hr className='orangeDivider' />
      </div>
        </div>
      </div>



      <div className='row' id="contact">
      <form method="POST" encType='multipart/form-data'>
    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="text" class="form-control" name="name" value={formData.name} onChange={handleChange} id="firstName" placeholder="Enter your first name" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" id="lastName" placeholder="Enter your last name" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="email" class="form-control" name="email" value={formData.email} onChange={handleChange} id="email" placeholder="Enter your email" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" name="mobile" value={formData.mobile} onChange={handleChange} id="mobile" placeholder="Enter your mobile" />
      </div>
    </div>


    <div class="mb-3">
      
      <textarea class="form-control" name="msg" id="message" value={formData.msg} onChange={handleChange} rows="4" placeholder="Enter your message"></textarea>
    </div>

    <div class="mb-3">
      <button type="submit" onClick={handleContact}  class="btn btn-primary contact-btn">Submit</button>
    </div>
  </form>
      </div>
    </div>



    <div className='container-fluid about-section mt-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-12 p-2'>
      <div className='position-relative d-flex flex-column'>
        
        <h2>Get Directions</h2>
        <hr className='orangeDivider' />
      </div>
        </div>
      </div>

        <div className='row d-flex align-items-center'>
        <div className='col-md-7'>
          <h4 className='text-orange' style={{textTransform:"uppercase"}}>Gryphon Media Craft</h4>
          <h6 className="mt-3">Address:</h6>
          <h4>Paras Tierea, Sector - 137, Noida, Uttar Pradesh - 201305</h4>

          <h6 className='mt-3'>Mobile:</h6>
          <h4>+91-8076022768</h4>

          <h6 className='mt-3'>Email:</h6>
          <h4>growth@gryphonmediacraft.com</h4>
        </div>

        <div className='col-md-5'>
          <img src={`${process.env.PUBLIC_URL}/mapphoto.png`} style={{height:'500px',width:'100%',borderRadius:'30px',filter:'grayscale(1) brightness(0.7)'}} />
        </div>
        </div>

    </div>
    </div>

        <Footer />

     
    </>
  )
}

export default Homepage